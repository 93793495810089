<template>
  <div>
    <!-- First Row -->
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="9"
        lg="8"
        md="7"
      >
        <UserViewUserInfoCard
          v-if="model"
          v-model="model"
        />
      </b-col>
      <b-col
        cols="12"
        md="5"
        xl="3"
        lg="4"
      >
        <UserViewUserPlanCard
          v-if="model"
          v-model="model"
        />
      </b-col>
    </b-row>

    <b-tabs
      pills
    >
      <!-- Tab: Lịch sử đặt hàng -->
      <b-tab
        active
        @click="changeTab(1)"
      >
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Lịch sử đặt hàng') }}</span>
        </template>
        <template>
          <b-card
            no-body
          >
            <vue-good-table
              v-if="items && items.length > 0"
              ref="promotion-products"
              class="custom-good-table custom-good-table-ctrl-border"
              :columns="fields"
              :rows="items"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Warehouses -->
                <span v-if="props.column.field === 'id'">
                  <router-link :to="`${props.row.slug}`">{{ props.row.name }}</router-link>
                </span>

                <!-- Column: expected_date -->
                <span v-else-if="props.column.field === 'expected_date'">
                  <span class="text-nowrap">{{ (props.row.expected_date) }}</span>
                </span>

                <!-- Column: Providers -->
                <span v-else-if="props.column.field === 'supplier'">
                  <span class="text-nowrap">{{ props.row.supplier.name }}</span>
                </span>

                <!-- Column: Warehouses -->
                <span v-else-if="props.column.field === 'storage_id'">
                  <span class="text-nowrap">{{ props.row.storages_name }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    class="d-block"
                    :variant="mixStatus('requestGood', props.row.status).color"
                  >
                    {{ mixStatus('requestGood', props.row.status).name }}
                  </b-badge>
                </span>

                <!-- Column: Quantity -->
                <span v-else-if="props.column.field === 'totalQuantity'">
                  <b-progress
                    v-model="props.row.currentQuantity"
                    :max="props.row.total_quantity"
                    variant="success"
                  />
                  <div class="mb-0 text-right">
                    <small>{{ props.row.currentQuantity || '--' }} / {{ props.row.total_quantity }}</small>
                  </div>
                </span>

                <!-- Column: Total price -->
                <span v-else-if="props.column.field === 'total_price'">
                  <span
                    v-if="props.row.vat !== 0"
                    class="text-nowrap"
                  >{{ unitFormatOriginal(props.row.total_price + (props.row.total_price * props.row.vat / 100)) }}</span>
                  <span
                    v-else
                    class="text-nowrap"
                  >{{ unitFormatOriginal(props.row.total_price) }}</span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5','10','20', '50', '100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </template>
      </b-tab>

      <!-- Tab: Lịch sử nhập hàng -->
      <b-tab
        @click="changeTab(2)"
      >
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Lịch sử nhập hàng') }}</span>
        </template>
        <template>
          <b-card
            no-body
          >
            <vue-good-table
              v-if="items && items.length > 0"
              ref="promotion-products"
              class="custom-good-table custom-good-table-ctrl-border"
              :columns="fieldsNH"
              :rows="items"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Warehouses -->
                <span v-if="props.column.field === 'code'">
                  <router-link :to="`${props.row.slug}`">{{ props.row.name }}</router-link>
                </span>

                <!-- Column: expected_date -->
                <span v-else-if="props.column.field === 'expected_date'">
                  <span class="text-nowrap">{{ (props.row.expected_date) }}</span>
                </span>

                <!-- Column: Providers -->
                <span v-else-if="props.column.field === 'supplier'">
                  <span class="text-nowrap">{{ props.row.supplier.name }}</span>
                </span>

                <!-- Column: Warehouses -->
                <span v-else-if="props.column.field === 'storage_id'">
                  <span class="text-nowrap">{{ props.row.storages_name }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    class="d-block"
                    :variant="mixStatus('requestGood', props.row.status).color"
                  >
                    {{ mixStatus('requestGood', props.row.status).name }}
                  </b-badge>
                </span>

                <!-- Column: Quantity -->
                <span v-else-if="props.column.field === 'totalQuantity'">
                  <b-progress
                    v-model="props.row.currentQuantity"
                    :max="props.row.total_quantity"
                    variant="success"
                  />
                  <div class="mb-0 text-right">
                    <small>{{ props.row.currentQuantity || '--' }} / {{ props.row.total_quantity }}</small>
                  </div>
                </span>

                <!-- Column: Total price -->
                <span v-else-if="props.column.field === 'price'">
                  <span class="text-nowrap">{{ unitFormatOriginal(props.row.price) }}</span>
                </span>

                <!-- Column: Total debt -->
                <span v-else-if="props.column.field === 'debt'">
                  <span class="text-nowrap">{{ unitFormatOriginal(props.row.debt) }}</span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5','10','20', '50', '100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </template>
      </b-tab>
      <!-- Tab: Payment history -->
      <b-tab
        @click="changeTab(3)"
      >
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Lịch sử thanh toán') }}</span>
        </template>
        <template>
          <b-card
            no-body
          >
            <vue-good-table
              v-if="items && items.length > 0"
              ref="promotion-products"
              class="custom-good-table custom-good-table-ctrl-border"
              :columns="fieldstt"
              :rows="items"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Warehouses -->
                <span v-if="props.column.field === 'code'">
                  <router-link :to="`${props.row.slug}`">{{ props.row.name }}</router-link>
                </span>

                <!-- Column: expected_date -->
                <span v-else-if="props.column.field === 'expected_date'">
                  <span class="text-nowrap">{{ (props.row.expected_date) }}</span>
                </span>

                <!-- Column: Providers -->
                <span v-else-if="props.column.field === 'supplier'">
                  <span class="text-nowrap">{{ props.row.supplier.name }}</span>
                </span>

                <!-- Column: Warehouses -->
                <span v-else-if="props.column.field === 'storage_id'">
                  <span class="text-nowrap">{{ props.row.storages_name }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    class="d-block"
                    :variant="mixStatus('receivingGoodDebt', props.row.status).color"
                  >
                    {{ mixStatus('receivingGoodDebt', props.row.status).name }}
                  </b-badge>
                </span>

                <!-- Column: Quantity -->
                <span v-else-if="props.column.field === 'totalQuantity'">
                  <b-progress
                    v-model="props.row.currentQuantity"
                    :max="props.row.total_quantity"
                    variant="success"
                  />
                  <div class="mb-0 text-right">
                    <small>{{ props.row.currentQuantity || '--' }} / {{ props.row.total_quantity }}</small>
                  </div>
                </span>

                <!-- Column: Total price -->
                <span v-else-if="props.column.field === 'price'">
                  <span class="text-nowrap">{{ unitFormatOriginal(props.row.price) }}</span>
                </span>

                <!-- Column: Total debt -->
                <span v-else-if="props.column.field === 'debt'">
                  <span class="text-nowrap">{{ unitFormatOriginal(props.row.debt) }}</span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5','10','20', '50', '100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </template>
      </b-tab>
      <!-- Tab: Overdue debt -->
      <b-tab
        @click="changeTab(4)"
      >
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Chưa thanh toán / Nợ quá hạn') }}</span>
        </template>
        <template>
          <b-card
            no-body
          >
            <vue-good-table
              v-if="items && items.length > 0"
              ref="promotion-products"
              class="custom-good-table custom-good-table-ctrl-border"
              :columns="fieldstt"
              :rows="items"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Warehouses -->
                <span v-if="props.column.field === 'code'">
                  <router-link :to="`${props.row.slug}`">{{ props.row.name }}</router-link>
                </span>

                <!-- Column: expected_date -->
                <span v-else-if="props.column.field === 'expected_date'">
                  <span class="text-nowrap">{{ (props.row.expected_date) }}</span>
                </span>

                <!-- Column: Providers -->
                <span v-else-if="props.column.field === 'supplier'">
                  <span class="text-nowrap">{{ props.row.supplier.name }}</span>
                </span>

                <!-- Column: Warehouses -->
                <span v-else-if="props.column.field === 'storage_id'">
                  <span class="text-nowrap">{{ props.row.storages_name }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    class="d-block"
                    :variant="mixStatus('receivingGoodDebt', props.row.status).color"
                  >
                    {{ mixStatus('receivingGoodDebt', props.row.status).name }}
                  </b-badge>
                </span>

                <!-- Column: Quantity -->
                <span v-else-if="props.column.field === 'totalQuantity'">
                  <b-progress
                    v-model="props.row.currentQuantity"
                    :max="props.row.total_quantity"
                    variant="success"
                  />
                  <div class="mb-0 text-right">
                    <small>{{ props.row.currentQuantity || '--' }} / {{ props.row.total_quantity }}</small>
                  </div>
                </span>

                <!-- Column: Total price -->
                <span v-else-if="props.column.field === 'price'">
                  <span class="text-nowrap">{{ unitFormatOriginal(props.row.price) }}</span>
                </span>

                <!-- Column: Total debt -->
                <span v-else-if="props.column.field === 'debt'">
                  <span class="text-nowrap">{{ unitFormatOriginal(props.row.debt) }}</span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5','10','20', '50', '100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </template>
      </b-tab>
    </b-tabs>
    <b-card title="Lịch sử">
      <div class="input-note ml-1">
        <div class="icon-user-chat">
          <feather-icon
            icon="UserIcon"
            size="40"
          />
        </div>
        <div class="ctr-pd-rp">
          <b-input-group class="pb-5">
            <b-form-input
              v-model="comment"
              placeholder="Thêm nội dung ghi chú"
            />
            <b-input-group-append>
              <b-button variant="primary">
                <feather-icon
                  icon="SendIcon"
                  size="20"
                  @click="hanldeComment"
                />
              </b-button>
            </b-input-group-append>
            <!-- <div class="date">
              16/07/2022
            </div> -->
          </b-input-group>
          <div
            v-for="(item, index) in model.comment"
            :key="index"
            class="one-comment d-flex mb-5 pr-4"
          >
            <div class="icon-user-chat1 mr-1">
              <feather-icon
                icon="UserIcon"
                size="40"
              />
            </div>
            <div class="title-content-block">
              <div class="title">
                <strong class="mr-1">{{ obUsers[`id_${item.user_id}`].fullname }}</strong>
                <!-- <span>{{ item.created_at }}</span> -->
              </div>
              <div class="note">
                {{ item.comment }}
              </div>
            </div>
            <div
              class="close"
              @click="hanldeDeleteComment(item.created_at)"
            >
              X
            </div>
            <div class="date">
              <span>{{ item.created_at }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <app-timeline>
        <app-timeline-item>
          <div class="d-flex flex-sm-row flex-wrap justify-content-between mb-1">
            <h6>
              Phiếu được tạo bởi
              <router-link
                to="/user/edit"
                class="text-primary"
              >
                Anh Huân
              </router-link>
            </h6>
            <div class="date-cm">
              11:02 SA
            </div>
          </div>
        </app-timeline-item>
      </app-timeline> -->
      <hr>
      <b-button
        variant="danger"
        type="button"
        to="add"
        class="mt-1"
      >
        {{ $t('Kết thúc') }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  // BAlert,
  // BLink,
  BTab,
  BTabs,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BBadge,
  BFormInput,
  BFormSelect,
  BPagination,
  BProgress,
  // BProgressBar,
} from 'bootstrap-vue'
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { VueGoodTable } from 'vue-good-table'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import UserViewUserPlanCard from './components/UserViewUserPlanCard.vue'
import UserViewUserInfoCard from './components/UserViewUserInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    // BAlert,
    // BLink,
    BTab,
    BTabs,
    BCard,
    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    // AppTimeline,
    // AppTimelineItem,
    VueGoodTable,
    BBadge,
    BFormSelect,
    BPagination,
    BProgress,
    // BProgressBar,
  },
  mixins: [general],
  data() {
    return {
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageLength: 10,
      userData: [
        {
          id: 1,
          fullName: 'Galen Slixby',
          company: 'Yotz PVT LTD',
          role: 'editor',
          username: 'gslixby0',
          country: 'El Salvador',
          contact: '(479) 232-9151',
          email: 'gslixby0@abc.net.au',
          currentPlan: 'enterprise',
          status: 'inactive',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
        {
          id: 2,
          fullName: 'Halsey Redmore',
          company: 'Skinder PVT LTD',
          role: 'author',
          username: 'hredmore1',
          country: 'Albania',
          contact: '(472) 607-9137',
          email: 'hredmore1@imgur.com',
          currentPlan: 'team',
          status: 'pending',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
        {
          id: 3,
          fullName: 'Marjory Sicely',
          company: 'Oozz PVT LTD',
          role: 'maintainer',
          username: 'msicely2',
          country: 'Russia',
          contact: '(321) 264-4599',
          email: 'msicely2@who.int',
          currentPlan: 'enterprise',
          status: 'active',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
        {
          id: 4,
          fullName: 'Cyrill Risby',
          company: 'Oozz PVT LTD',
          role: 'maintainer',
          username: 'crisby3',
          country: 'China',
          contact: '(923) 690-6806',
          email: 'crisby3@wordpress.com',
          currentPlan: 'team',
          status: 'inactive',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
        {
          id: 5,
          fullName: 'Maggy Hurran',
          company: 'Aimbo PVT LTD',
          role: 'subscriber',
          username: 'mhurran4',
          country: 'Pakistan',
          contact: '(669) 914-1078',
          email: 'mhurran4@yahoo.co.jp',
          currentPlan: 'enterprise',
          status: 'pending',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
      ],
      fields: [
        {
          field: 'id',
          label: 'ID',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'expected_date',
          label: 'Expected date',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'supplier',
          label: 'Supplier',
          sortable: false,
          thClass: 'text-left',
        },
        {
          field: 'storage_id',
          label: 'Warehouse',
          sortable: false,
          thClass: 'text-left',
        },
        {
          field: 'status',
          label: 'Status',
          sortable: false,
          width: '150px',
          thClass: 'text-left',
        },
        {
          field: 'totalQuantity',
          label: 'Quantity',
          sortable: false,
          thClass: 'text-right',
        },
        {
          field: 'total_price',
          label: 'Total price',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right',
        },
      ],
      fieldsNH: [
        {
          field: 'code',
          label: 'ID',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'created_at',
          label: 'Created at',
          sortable: false,
        },
        {
          field: 'supplier_name',
          label: 'Supplier',
          sortable: false,
        },
        {
          field: 'warehouse',
          label: 'Warehouse',
          sortable: false,
        },
        {
          field: 'price',
          label: 'Total price',
          type: 'number',
          thClass: 'custom-th-class',
          sortable: false,
        },
        {
          field: 'debt',
          label: 'Còn nợ',
          type: 'number',
          thClass: 'custom-th-class',
          sortable: false,
        },
      ],
      fieldstt: [
        {
          field: 'code',
          label: 'ID',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'created_at',
          label: 'Created at',
          sortable: false,
        },
        {
          field: 'supplier_name',
          label: 'Supplier',
          sortable: false,
        },
        {
          field: 'warehouse',
          label: 'Warehouse',
          sortable: false,
        },
        {
          field: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          field: 'price',
          label: 'Total price',
          type: 'number',
          thClass: 'custom-th-class',
          sortable: false,
        },
        {
          field: 'debt',
          label: 'Còn nợ',
          type: 'number',
          thClass: 'custom-th-class',
          sortable: false,
        },
      ],
      fieldsnqh: [
        { field: 'map', label: 'Mã phiếu', sortable: false },
        { field: 'combo_period', label: 'Combo period', sortable: false },
        { field: 'date_of_payment', label: 'Date of payment', sortable: false },
        { field: 'status', label: 'Status', sortable: true },
        { field: 'total price', label: 'Total price', sortable: false },
        { field: 'debt', label: 'Debt', sortable: false },
      ],
      itemstt: [
        {
          map: 125751,
          creator: 'Anh Huân',
          status: 'finished',
          status_label: 'Đã thanh toán',
          combo_period: '10:01 21/08/2021',
          priceTotal: 120000000,
        },
        {
          map: 125751,
          creator: 'Anh Huân',
          status: 'inProgress',
          status_label: 'Đang xử lý',
          combo_period: '10:01 21/08/2021',
          priceTotal: 120000000,
        },
        {
          map: 125751,
          creator: 'Anh Huân',
          status: 'finished',
          status_label: 'Đã thanh toán',
          combo_period: '10:01 21/08/2021',
          priceTotal: 120000000,
        },
      ],
      itemsnqh: [
        {
          map: 125751,
          debt: 15000000000,
          status: 'finished',
          status_label: 'Đang xử lý',
          combo_period: '21/08/2021 10:01 CH',
          date_of_payment: '22/08/2021 10:01 SA',
          priceTotal: 120000000,
        },
        {
          map: 125751,
          debt: 15000000000,
          status: 'inProgress',
          status_label: 'Quá hạn',
          date_of_payment: '22/08/2021 10:01 SA',
          combo_period: '21/08/2021 10:01 CH',
          priceTotal: 120000000,
        },
        {
          map: 125751,
          debt: 15000000000,
          status: 'finished',
          status_label: 'Đang xử lý',
          date_of_payment: '22/08/2021 10:01 SA',
          combo_period: '21/08/2021 10:01 CH',
          priceTotal: 120000000,
        },
      ],
      items: [
        {
          iddh: 125751,
          supplier: 'Nhà cung cấp 1',
          warehouse: 'Nhật tảo',
          sku: 'SP01',
          status: 'inProgress',
          status_label: 'Đang xử lý',
          combo_period: '10:01 21/08/2021',
          count: 60,
          countTotal: 100,
          priceTotal: 120000000,
        },
        {
          iddh: 125752,
          supplier: 'Nhà cung cấp 2',
          warehouse: 'Kho Online',
          sku: 'SP02',
          status: 'finished',
          status_label: 'Đã thanh toán',
          combo_period: '10:01 21/08/2021',
          count: 90,
          countTotal: 100,
          priceTotal: 120000000,
        },
        {
          iddh: 125753,
          supplier: 'Nhà cung cấp 3',
          warehouse: 'Tân Định Q1',
          sku: 'SP03',
          avatar: 'https://cf.shopee.vn/file/f4770e5cd375729e303e42c2b919e03f',
          status: 'finished',
          status_label: 'Đã thanh toán',
          combo_period: '10:01 21/08/2021',
          count: 20,
          countTotal: 100,
          priceTotal: 120000000,
        },
        {
          iddh: 125754,
          supplier: 'Nhà cung cấp 4',
          warehouse: 'Nhật tảo',
          sku: 'SP04',
          avatar: 'https://cf.shopee.vn/file/bedcc87c28b4f485716ae82a58df6304',
          status: 'inProgress',
          status_label: 'Đang xử lý',
          combo_period: '10:01 21/08/2021',
          count: 70,
          countTotal: 100,
          priceTotal: 120000000,
        },
        {
          iddh: 125755,
          supplier: 'Nhà cung cấp 5',
          warehouse: 'Nhật tảo',
          sku: 'SP05',
          avatar: 'https://cf.shopee.vn/file/bedcc87c28b4f485716ae82a58df6304',
          status: 'finished',
          status_label: 'Đã thanh toán',
          combo_period: '10:01 21/08/2021',
          count: 60,
          countTotal: 100,
          priceTotal: 120000000,
        },
        {
          iddh: 125756,
          supplier: 'Nhà cung cấp 6',
          warehouse: 'Nhật tảo',
          sku: 'SP06',
          avatar: 'https://cf.shopee.vn/file/f4770e5cd375729e303e42c2b919e03f',
          status: 'finished',
          status_label: 'Đã thanh toán',
          combo_period: '10:01 21/08/2021',
          count: 90,
          countTotal: 100,
          priceTotal: 120000000,
        },
        {
          iddh: 125757,
          supplier: 'Nhà cung cấp 7',
          warehouse: 'Nhật tảo',
          sku: 'SP07',
          status: 'inProgress',
          status_label: 'Đang xử lý',
          combo_period: '10:01 21/08/2021',
          count: 100,
          countTotal: 100,
          priceTotal: 120000000,
        },
        {
          iddh: 125758,
          supplier: 'Nhà cung cấp 8',
          warehouse: 'Nhật tảo',
          sku: 'SP08',
          status: 'finished',
          status_label: 'Đã thanh toán',
          combo_period: '10:01 21/08/2021',
          count: 30,
          countTotal: 100,
          priceTotal: 120000000,
        },
        {
          iddh: 125759,
          supplier: 'Nhà cung cấp 9',
          warehouse: 'Nhật tảo',
          sku: 'SP09',
          avatar: 'https://cf.shopee.vn/file/f4770e5cd375729e303e42c2b919e03f',
          status: 'finished',
          status_label: 'Đã thanh toán',
          combo_period: '10:01 21/08/2021',
          count: 50,
          countTotal: 100,
          priceTotal: 120000000,
        },
      ],
      model: {
        name: null,
        slug: null,
        avatar: null,
        contact_name: null,
        zip_code: null,
        country: null,
        web_company: null,
        short_content: null,
        address: null,
        phone: null,
        email: null,
        name_bank: null,
        number_bank: null,
        branch_bank: null,
        tin: null,
        business_license: null,
        account_holder_bank: null,
        swift_code: null,
        tax_code: null,
        position: null,
        is_active: true,
        dashboard: null,
        comment: [],
      },
      obUsers: {},
      comment: null,
      tabSelected: 1,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        inProgress : 'light-danger',
        finished     : 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  watch: {
    currentPage() {
      this.changeTab(this.tabSelected)
    },
    pageLength() {
      this.changeTab(this.tabSelected)
    },
  },
  created() {
    this.loadDetail()
    this.loadRequestGood()
    // this.loadReceivingGoods()
    this.changeTab(1)
  },
  methods: {
    changeTab(id = 1) {
      if (id === 1) {
        this.tabSelected = id
        this.loadRequestGood()
      }
      if (id === 2) {
        this.tabSelected = id
        this.loadReceivingGoods()
      }
      if (id === 3) {
        this.tabSelected = id
        this.loadReceivingGoodsPayed()
      }
      if (id === 4) {
        this.tabSelected = id
        this.loadReceivingGoodsPay()
      }
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const year = new Date().getFullYear()
        const dateFrom = `${year}-01-01`
        const dateTo = `${year}-12-31`
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/supplier/${this.$route.params.id}?site_id=${siteId}&dashboard=true&date_from=${dateFrom}&date_to=${dateTo}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = res.data.data
            this.model.dashboard = res.data.data.dashboard
            this.model.comment = res.data.data.history_comment ? JSON.parse(res.data.data.history_comment) : []
            const listIdUser = []
            this.model.comment.map(x => listIdUser.push(x.user_id))
            this.loadUser(listIdUser)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },

    async loadUser(listId) {
      const res = await this.mixGet('/v2/users', { list_id: JSON.stringify(listId) })
      if (res.status) {
        res.data.items.map(val => {
          const temp = {}
          temp[`id_${val.id}`] = val
          this.obUsers = { ...this.obUsers, ...temp }
          return true
        })
      }
    },

    async loadRequestGood() {
      const params = {
        site_id: JSON.parse(localStorage.getItem('siteID')).id,
        supplier: this.$route.params.id,
        'sort[]': 'id,desc',
        limit: this.pageLength,
        page: this.currentPage,
      }
      const res = await this.mixGet('/requests_goods', params)
      if (res.status) {
        this.totalRows = res.data.total
        const temp = []
        this.items = []
        // eslint-disable-next-line array-callback-return
        res.data.items.map(item => {
          temp.push({
            ...item,
            name: `PDH-${item.id}`,
            slug: `/purchasing-orders/edit/${item.id}`,
            currentQuantity: item.receiving_good ? item.receiving_good.receiving_goods_product[0].quantity : '',
          })
        })
        this.items = temp
      }
    },

    async loadReceivingGoods() {
      const params = {
        site_id: JSON.parse(localStorage.getItem('siteID')).id,
        supplier: this.$route.params.id,
        'sort[]': 'id,desc',
        limit: this.pageLength,
        page: this.currentPage,
      }
      const res = await this.mixGet('/receivings_goods', params)
      if (res.status) {
        this.totalRows = res.data.total

        const temp = []
        this.items = []
        // eslint-disable-next-line array-callback-return
        res.data.items.map(val => {
          const vatTotal = (val.money_payment / 100) * val.request_good.vat
          temp.push(
            {
              id: val.id,
              request_goods_id: val.request_goods_id,
              name: `PN-${val.id}`,
              slug: `/inventory-receives/edit/${val.id}`,
              created_at: val.created_at,
              supplier_name: val.request_good.supplier.name,
              warehouse: val.request_good.storage.name,
              price: val.money_payment + vatTotal,
              debt: (val.money_payment + vatTotal) - val.money_paid,
            },
          )
        })
        this.items = temp
      }
    },

    async loadReceivingGoodsPayed() {
      const params = {
        site_id: JSON.parse(localStorage.getItem('siteID')).id,
        supplier_id: this.$route.params.id,
        'sort[]': 'id,desc',
        is_debt: '0',
        limit: this.pageLength,
        page: this.currentPage,
      }
      const res = await this.mixGet('/receivings_goods', params)
      if (res.status) {
        this.totalRows = res.data.total

        const temp = []
        this.items = []
        // eslint-disable-next-line array-callback-return
        res.data.items.map(val => {
          const vatTotal = (val.money_payment / 100) * val.request_good.vat
          temp.push(
            {
              id: val.id,
              request_goods_id: val.request_goods_id,
              name: `PN-${val.id}`,
              slug: `/inventory-receives/edit/${val.id}`,
              created_at: val.created_at,
              supplier_name: val.request_good.supplier.name,
              warehouse: val.request_good.storage.name,
              price: val.money_payment + vatTotal,
              debt: (val.money_payment + vatTotal) - val.money_paid,
              status: val.is_debt,
            },
          )
        })
        this.items = temp
      }
    },

    async loadReceivingGoodsPay() {
      const params = {
        site_id: JSON.parse(localStorage.getItem('siteID')).id,
        supplier_id: this.$route.params.id,
        'sort[]': 'id,desc',
        is_debt: '1',
        limit: this.pageLength,
        page: this.currentPage,
      }
      const res = await this.mixGet('/receivings_goods', params)
      if (res.status) {
        this.totalRows = res.data.total

        const temp = []
        this.items = []
        // eslint-disable-next-line array-callback-return
        res.data.items.map(val => {
          const vatTotal = (val.money_payment / 100) * val.request_good.vat
          temp.push(
            {
              id: val.id,
              request_goods_id: val.request_goods_id,
              name: `PN-${val.id}`,
              slug: `/inventory-receives/edit/${val.id}`,
              created_at: val.created_at,
              supplier_name: val.request_good.supplier.name,
              warehouse: val.request_good.storage.name,
              price: val.money_payment + vatTotal,
              debt: (val.money_payment + vatTotal) - val.money_paid,
              status: val.is_debt,
            },
          )
        })
        this.items = temp
      }
    },

    async hanldeComment() {
      const res = await this.mixPut(`/supplier/${this.model.id}`, { comment: this.comment })
      if (res.status) {
        this.model.comment = JSON.parse(res.data[0].history_comment)
        this.comment = null
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thông báo!',
            icon: 'AlertOctagonIcon',
            variant: 'success',
            text: 'Thêm comment thành công!',
          },
        })
      }
    },

    async hanldeDeleteComment(createdAt) {
      const params = {
        delete_comment: createdAt,
      }
      const res = await this.mixPut(`/supplier/${this.model.id}`, params)
      if (res.status) {
        this.model.comment = JSON.parse(res.data[0].history_comment)
        this.loadDetail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thông báo!',
            icon: 'AlertOctagonIcon',
            variant: 'success',
            text: 'Xoá comment thành công!',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thông báo!',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: 'Xoá comment thất bại!',
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
.input-note .input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none;
}
</style>

<style lang="scss" scoped>
.ctrl-pd-1-5{
  padding: 1.5rem
}
.input-note{
  display: flex;
  align-items: center;
  position: relative;

  .date{
    position: absolute;
    bottom: 2rem;
    left: 0;
    font-weight: 700;
  }

  .btn{
    padding: 0 1.5rem;
    svg{
      transform: rotate(45deg);
      color: #fff
    }
  }

  .icon-user-chat{
    position: absolute;
    top: 0;
    left: -18px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: #fff;
    background-color:rgb(60, 148, 209);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid rgb(60, 148, 209);
    z-index: 1;
  }
  .icon-user-chat1{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: #fff;
    background-color:rgb(60, 148, 209);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid rgb(60, 148, 209);
    z-index: 1;
  }
  .one-comment{
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    border-radius: 4px;
    position: relative;
    .close{
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      color: #ea5455;
      cursor: pointer;
    }
    .date{
      bottom: -2rem;
    }
  }
  .input-group{
    position: relative;
  }
  .ctr-pd-rp{
    padding-left: 2.5rem;
    border-left: 1px solid #ebe9f1;
    width: 100%;
  }
}
</style>

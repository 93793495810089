<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Thông Tin Liên Hệ
      </h5>
      <b-badge variant="light-primary">
        <feather-icon
          icon="Edit2Icon"
          size="18"
        />
      </b-badge>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">{{ value.contact_name }}</span>
        </li>
        <li class="my-25">
          <span class="align-middle">{{ value.email }}</span>
        </li>
        <li>
          <span class="align-middle">{{ value.phone }}</span>
        </li>
        <hr>
        <strong>Địa Chỉ</strong>
        <li>
          <span class="align-middle">{{ value.address }}</span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style>

</style>

<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="icon-avata">
            F
          </div>
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="d-flex mb-0">
                <div class="title mr-1">
                  {{ value.name }}
                </div>
                <b-badge
                  v-if="value.is_active"
                  class="d-block f-12"
                  variant="light-success"
                >
                  Đang hoạt động
                </b-badge>
                <b-badge
                  v-else
                  class="d-block f-12"
                  variant="light-danger"
                >
                  Dừng hoạt động
                </b-badge>
              </h4>
              <div class="card-text">
                {{ value.web_company }}
              </div>
              <div class="card-text">
                Mã số thuế: {{ value.tin }}
              </div>
            </div>
          </div>
        </div>

        <!-- Nợ phải trả -->
        <b-row>
          <b-col
            cols="12"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="text-center">
              <div class="title">
                Nợ phải trả
              </div>
              <strong class="mb-0 price-head">
                {{ unitFormatOriginal(value.dashboard.total_debt) }}
              </strong>
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="text-center">
              <div class="title">
                Tổng mua
              </div>
              <strong class="mb-0 price-head">
                {{ unitFormatOriginal(value.dashboard.total_buy) }}
              </strong>
            </div>
          </b-col>
        </b-row>
        <!-- <label class="mt-3">
          <strong>
            Ghi chú
          </strong>
        </label>
        <b-input-group class="note-head">
          <b-form-input placeholder="Thêm nội dung ghi chú" />
          <b-input-group-append>
            <b-button variant="primary">
              <feather-icon
                icon="SendIcon"
                size="20"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group> -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  // BInputGroup,
  // BFormInput,
  // BInputGroupAppend,
  // BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormInput,
    // BButton,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup() {
    return {
      avatarText,
      userData: {
        id: 1,
        fullName: 'Nguyễn Văn A',
        company: 'Yotz PVT LTD',
        role: 'editor',
        level: 'đồng',
        username: 'Văn A',
        country: 'Tp.HCM',
        contact: '(479) 232-9151',
        email: 'gslixby0@abc.net.au',
        currentPlan: 'enterprise',
        status: 'Hoạt Động',
        avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        inProgress : 'light-success',
        upComing     : 'light-danger',
        finished     : 'light-dark',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {
    resolveUserRoleVariant(role) {
      if (role === 'subscriber') return 'primary'
      if (role === 'author') return 'warning'
      if (role === 'maintainer') return 'success'
      if (role === 'editor') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    },
  },
}
</script>

<style lang="sass">
.price-head
  font-size: 18px

.input-note .input-group:not(.bootstrap-touchspin):focus-within
  box-shadow: none

.note-head
  &.input-group:not(.bootstrap-touchspin):focus-within
    box-shadow: none
  input
    height: 100%

.icon-avata
  width: 36px
  height: 36px
  background-color: rgb(252, 98, 93)
  color: #fff
  font-weight: 600
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%

.f-12
  font-size: 12px
</style>
